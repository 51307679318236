import React from "react";
import profile from "../profile.jpg";
import { content } from "../data/content";

export const AboutContent = () => {
  return (
    <div>
      <div className="sm:flex">
        <div className="sm:w-2/6 sm:pr-8">
          <img src={profile} alt="Noah Fernandes profile" className="rounded" />
        </div>
        <div className="sm:w-4/6 text-mud">
          <p className="text-3xl font-light font-serif mt-4 sm:mt-0">
            Welcome to my website!
          </p>
          <p className="text-lg mt-4">
            I'm Noah, and I'm genuinely pleased that you're here, taking
            proactive steps towards healing and growth.
          </p>
          <p className="mt-4">
            I believe that the path to deep healing and transformation begins
            from within. As a certified {content.role}, I support individuals in
            their journey of healing emotional wounds and rediscovering their
            authentic selves. My approach is trauma-informed and focuses on
            empowering you to reconnect with your core values, cultivate
            self-love, and foster personal growth in a way that feels true to
            who you are.
          </p>
          <p className="mt-4">
            Through guided meditations, breathwork, and practical exercises
            tailored to your unique situation, I provide a safe, non-judgmental
            space for self-exploration. Together, we'll dive into the root
            causes of your pain, helping you move forward with more clarity,
            self-compassion, and resilience.
          </p>
          <p className="mt-4">
            Whether you're recovering from a difficult relationship, navigating
            the complexities of trauma, or simply seeking to feel more whole, my
            mission is to guide you toward a life of authenticity, empowerment,
            and balance.
          </p>
          <p className="mt-4">
            Your experience is unique, and you will have my personalised
            attention at every step of this journey.
          </p>
        </div>
      </div>
    </div>
  );
};
