import React from "react";
import { scrollTo } from "../../utils/ScrollTo";
import { content } from "../../data/content";

export const AppHeader = () => {
  return (
    <header className="hidden sm:block absolute top-0 left-0 right-0 bg-sand/30 backdrop-blur-sm border-b border-mud/20">
      <div className="flex justify-between items-center py-6 px-10 text-slate">
        <div className="text-left leading-none">
          <h1 className="text-2xl text-sand font-serif">{content.name}</h1>
          <span className="text-sm font-light text-sand">{content.role}</span>
        </div>
        <div className="flex sm:space-x-8">
          <button
            className="hover:text-mud text-sand"
            onClick={scrollTo("home")}
          >
            Home
          </button>
          <button
            className="hover:text-mud text-sand"
            onClick={scrollTo("about")}
          >
            About
          </button>
          <button
            className="hover:text-mud text-sand"
            onClick={scrollTo("services")}
          >
            Services & Prices
          </button>
          <button
            className="hover:text-mud text-sand"
            onClick={scrollTo("testimonials")}
          >
            Testimonials
          </button>
          <button
            className="hover:text-mud text-sand"
            onClick={scrollTo("contact")}
          >
            Contact
          </button>
        </div>
      </div>
    </header>
  );
};
