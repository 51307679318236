import React from "react";
import logo from "../logo.png";
import { content } from "../data/content";

export const HomeContent = () => {
  return (
    <div>
      <img
        src={logo}
        className="mt-0 sm:mt-12 w-40 m-auto"
        alt="Sprouting coconut"
      />
      <div className="text-center mt-20 ax-w-lg m-auto w-1/2">
        <h1 className="text-4xl text-sand tracking-wider font-serif">
          {content.name}
        </h1>
        <h2 className="text-2xl text-sand mt-2 font-light sm:font-thin">
          {content.role}
        </h2>
        <p className="mt-8 text-xl text-sand font-thin">
          Empowering deep transformation through trauma-informed care and
          authentic self-discovery.
        </p>
        <a
          className="bg-sand text-mud mt-8 inline-block py-2 px-4 uppercase rounded-sm"
          href="https://calendly.com/noah-counselling/discovery-call"
          target="_new"
        >
          Book a free discovery call
        </a>
      </div>
    </div>
  );
};
