import React from "react";

export const ServicesContent = () => {
  return (
    <div>
      <div className="sm:flex">
        <div className="sm:w-2/6 sm:pr-8">
          <h2 className="text-4xl font-serif">Services and Prices</h2>
        </div>
        <div className="sm:w-4/6">
          <p className="mt-4 sm:mt-0">
            These are the areas where I’ve cultivated significant experience and
            expertise, allowing me to provide compassionate, personalised
            support that meets you exactly where you are:
          </p>
          <ul className="list-disc ml-4 mt-4">
            <li>Healing from trauma with care and understanding</li>
            <li>
              Recovering after divorce, separation, or unhealthy relationships
            </li>
            <li>Nurturing a healthy relationship with yourself</li>
            <li>Rebuilding confidence and self-esteem</li>
            <li>Navigating grief with resilience and compassion</li>
            <li>Setting healthy boundaries and expressing your needs</li>
            <li>Building healthy and fulfilling relationship dynamics</li>
            <li>Cultivating inner peace and personal empowerment</li>
          </ul>
          <p className="mt-4">
            If any of these resonate with you, my specialised coaching and
            counselling services are here to support you on your journey of
            healing and growth.
          </p>

          <h3 className="mt-8 text-lg">
            What you can expect from our sessions
          </h3>
          <p className="mt-2">
            I provide a safe, judgment-free space for you to openly share your
            story. With compassion, we'll explore your situation and identify
            the areas you want to work on. Through guided meditations,
            breathwork, and practical exercises tailored to your unique needs, I
            will guide you toward developing a deep understanding of yourself.
            While I will always be here to support you, my goal is to help you
            discover the tools within yourself, so that whenever you're ready,
            you can confidently continue your journey on your own.
          </p>
          <p className="mt-2">
            For impactful and lasting results, I encourage committing to at
            least six sessions, spaced every two to four weeks. This isn’t an
            obligation but a recommendation, as many of my clients find this
            approach helps create meaningful change. Throughout our work
            together, we’ll stay present and flexible, addressing whatever
            emerges while keeping you aligned with your main goal. I believe
            that deep down, you know what you need better than anyone else,
            which is why I offer you the freedom to end the sessions whenever
            you feel ready. You are always welcome to return whenever you need
            further support.
          </p>

          <h3 className="mt-8 text-lg">Payment and Cancellation Policy</h3>
          <ul className="list-disc ml-4 mt-4">
            <li>60-minute session: 90 euros (including VAT)</li>
            <li>90-minute session: 135 euros (including VAT)</li>
          </ul>
          <p className="mt-4">
            Invoices will be sent after each session, with a payment term of 14
            days. You may reschedule or cancel a session free of charge if you
            notify me at least 48 hours in advance. If you do not attend a
            session without prior notice, I will unfortunately have to charge
            for the missed session. Please note that my services are not covered
            by insurance.
          </p>
        </div>
      </div>
    </div>
  );
};
